import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import useApiStyles from './useApiStyles';

const ModalNotificacion = ({ mensaje, visible, onClose }) => {
  const themeColors = useApiStyles(); // Obtener estilos del tema

  if (!visible) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 transition-opacity duration-300 ease-in-out">
      <div className="fixed inset-0 bg-gray-800 opacity-75 transition-opacity duration-300 ease-in-out"></div>
      <div className="bg-white rounded-lg shadow-lg p-6 z-10 max-w-sm mx-auto transform transition-transform duration-300 ease-in-out scale-100">
        <div className="flex flex-col items-center">
          <FaCheckCircle className="text-green-500" size={48} />
          <h2 className="text-lg font-bold mt-4 text-center">Producto agregado</h2>
          <p className="text-gray-700 mt-2 text-center">
            {mensaje}
          </p>
          <button
            className={`font-bold mt-4 w-full py-2 px-4 rounded-lg border-2 shadow-lg transition duration-300 ease-in-out ${themeColors ? themeColors.background : 'bg-green-500'} ${themeColors ? themeColors.hoverBackground : 'hover:bg-green-600'} ${themeColors ? themeColors.text : 'text-white'}`}
            onClick={onClose}
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalNotificacion;
