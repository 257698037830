import React from 'react';
import useCompanyInfo from './useCompanyInfo'; // Ajusta la ruta según la ubicación de tu archivo
import UrlValidator from './UrlValidator'; // Ajusta la ruta según la ubicación de tu archivo
import { SlWallet, SlArrowRight } from 'react-icons/sl';

const PayInfoButton = () => {
  const companyInfoData = useCompanyInfo();

  if (!companyInfoData || !companyInfoData.linkPay || !companyInfoData.texPay) {
    return null;
  }

  return (
    <div className="p-4">
      {/* Validar la URL usando UrlValidator */}
      <UrlValidator url={companyInfoData.linkPay} />

      {/* Pay Info Button */}
      <div className='text-lg border shadow-md rounded-lg bg-white w-full lg:w-80'>
        <a href={companyInfoData.linkPay} target="_blank" rel="noopener noreferrer" className="flex justify-between items-center w-full text-center rounded-md px-4 py-2 leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-opacity-90 hover:bg-gray-100 transition duration-200 ease-in-out">
          {companyInfoData.texPay.includes("Wompi") && <img src="/Wompi_LogoPrincipal.png" alt="Logo WOMPI" className="mr-2 h-12" />}
          {companyInfoData.texPay.includes("Bold") && <img src="/Bold_LogoPrincipal.png" alt="Logo Bold" className="mr-2 h-12" />}
          {!companyInfoData.texPay.includes("Wompi") && !companyInfoData.texPay.includes("Bold") && <SlWallet size={32} className="mr-2 text-gray-800" />}
          <div className='text-sm text-gray-800 text-start'>
            <span className='font-semibold'>{companyInfoData.texPay}</span>
          </div>
          <SlArrowRight className="text-gray-800" />
        </a>
        <div className='text-start flex flex-col space-y-2 p-2 md:hidden'>
          <p className='text-sm border-t pt-2'>
            El sistema de Pagos Seguros en Línea
          </p>
          <p className='text-xs text-gray-600'>
            Permite a los usuarios realizar transacciones directamente desde sus cuentas bancarias.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PayInfoButton;
