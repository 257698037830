import React from 'react';
import { CiFacebook } from "react-icons/ci";
import { FaTiktok } from 'react-icons/fa';
import { SlSocialInstagram, SlSocialSpotify, SlSocialYoutube } from "react-icons/sl";
import { FaXTwitter } from "react-icons/fa6";
import useCompanyInfo from './useCompanyInfo'; // Ajusta la ruta según la ubicación de tu archivo

const RedesSocialesButton = () => {
  const companyInfoData = useCompanyInfo();

  if (!companyInfoData) {
    // Si la información de la compañía no está disponible, retorna null o maneja el caso según tus necesidades
    return null;
  }

  // Extrae los valores de las redes sociales de la respuesta de la API
  const {
    facebookLink,
    twitterLink,
    instagramLink,
    youtubeLink,
    tiktokLink,
    spotifyLink
  } = companyInfoData;

  // Crea un objeto que mapee los nombres de las redes sociales a los enlaces y los iconos correspondientes
  const redesSociales = {
    facebook: { enlace: facebookLink, Icono: CiFacebook },
    twitter: { enlace: twitterLink, Icono: FaXTwitter },
    instagram: { enlace: instagramLink, Icono: SlSocialInstagram },
    youtube: { enlace: youtubeLink, Icono: SlSocialYoutube },
    tiktok: { enlace: tiktokLink, Icono: FaTiktok },
    spotify: { enlace: spotifyLink, Icono: SlSocialSpotify }
  };

  // Filtra las redes sociales que tienen enlaces válidos
  const redesValidas = Object.entries(redesSociales).filter(([_, { enlace }]) => enlace && enlace !== "#");

  // Oculta el componente si no hay redes sociales válidas
  if (redesValidas.length === 0) {
    return null;
  }

  return (
    <div className='my-4 p-4 mx-0 w-full'>
      <p className='mb-4 text-md font-light text-center text-gray-800 border-b border-b-gray-300 pb-1'>Conéctate con nosotros</p>
      <ul className="flex justify-center space-x-5 text-2xl">
        {redesValidas.map(([red, { enlace, Icono }], index) => (
          <li key={index}>
            <a href={enlace} title={`Abrir enlace de ${red}`} target="_blank" rel="noopener noreferrer" className="text-gray-800 hover:text-gray-600 transition duration-200">
              <Icono size={28} />
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RedesSocialesButton;
