import React, { useEffect, useState, useRef, useCallback } from 'react';
import { FaShoppingCart } from 'react-icons/fa';
import ModalProducto from './ModalProducto';
import Carrito from './Carrito';
import useCompanyInfo from './useCompanyInfo';
import { CiFaceSmile } from "react-icons/ci";
import useApiStyles from './useApiStyles';
import { IoAdd } from 'react-icons/io5';
import ModalNotificacion from './ModalNotificacion';

const ProductosList = () => {
    const [productos, setProductos] = useState([]);
    const [filtroCategoria, setFiltroCategoria] = useState('');
    const [busquedaProducto, setBusquedaProducto] = useState('');
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [carrito, setCarrito] = useState(() => {
        const savedCart = localStorage.getItem('carrito');
        return savedCart ? JSON.parse(savedCart) : [];
    });
    const [nombreUsuario, setNombreUsuario] = useState('');
    const [direccion] = useState('');
    const [carritoAbierto, setCarritoAbierto] = useState(false);

    const [productosCargados, setProductosCargados] = useState(24);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [nombreProductoNoEncontrado, setNombreProductoNoEncontrado] = useState('');
    const [descripcionProductoNoEncontrado, setDescripcionProductoNoEncontrado] = useState('');

    const companyInfo = useCompanyInfo();
    const themeColors = useApiStyles();
    const { telefonoWhatsApp, descuento, title_sales } = companyInfo || {};

    const productosListRef = useRef(null);

    const [modalVisible, setModalVisible] = useState(false); // Estado para controlar la visibilidad del modal
    const [modalMensaje, setModalMensaje] = useState(''); // Estado para el mensaje del modal

    const actualizarCantidad = useCallback((index, nuevaCantidad) => {
        if (nuevaCantidad < 1) return; // Evita cantidades menores que 1
        setCarrito(prevCarrito => {
            const nuevoCarrito = [...prevCarrito];
            nuevoCarrito[index].cantidad = nuevaCantidad;
            return nuevoCarrito;
        });
    }, []);

    useEffect(() => {
        const fetchProductos = async () => {
            setLoading(true);
            try {
                const timestamp = new Date().getTime();
                const response = await fetch(`/panel/productos/productos.json?timestamp=${timestamp}`);
                if (!response.ok) {
                    throw new Error('Error al cargar los productos');
                }
                const data = await response.json();
                setProductos(data.productos);
                setError('');
            } catch (error) {
                setError('Error al cargar los productos. Por favor, intenta de nuevo.');
            } finally {
                setLoading(false);
            }
        };

        fetchProductos();
    }, []);

    useEffect(() => {
        localStorage.setItem('carrito', JSON.stringify(carrito));
    }, [carrito]);

    useEffect(() => {
        const handleScroll = () => {
            if (!productosListRef.current) return;

            const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
            const productosListBottom = productosListRef.current.getBoundingClientRect().bottom;

            if (productosListBottom - clientHeight < 500) {
                setProductosCargados(prevProductosCargados => prevProductosCargados + 24);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const filtrarPorCategoria = useCallback((categoria) => {
        setFiltroCategoria(categoria);
        setProductosCargados(24); // Reiniciar el número de productos cargados al cambiar el filtro
    }, []);

    const buscarProducto = useCallback((event) => {
        setBusquedaProducto(event.target.value);
        setProductosCargados(24); // Reiniciar el número de productos cargados al realizar una búsqueda
    }, []);

    const mostrarDetalleProducto = useCallback((index) => {
        setSelectedIndex(index);
    }, []);

    const cerrarModal = useCallback(() => {
        setSelectedIndex(null);
    }, []);

    const handleClickCategoria = useCallback((categoria) => {
        filtrarPorCategoria(categoria);
    }, [filtrarPorCategoria]);

    const calcularPrecioOferta = (precio, descuento) => {
        return descuento > 0 ? Math.round(precio * (1 + descuento / 100)) : precio;
    };

    const agregarAlCarrito = useCallback((producto) => {
        setCarrito(prevCarrito => {
            const productoExistente = prevCarrito.find(item => item.uuid_str === producto.uuid_str);
            if (productoExistente) {
                return prevCarrito.map(item =>
                    item.uuid_str === producto.uuid_str ? { ...item, cantidad: item.cantidad + 1 } : item
                );
            } else {
                return [...prevCarrito, { ...producto, cantidad: 1 }];
            }
        });
        // Mostrar el modal
        setModalMensaje(`${producto.nombre} se ha agregado al carrito correctamente.`);
        setModalVisible(true);
        // Ocultar el modal después de 3 segundos
        setTimeout(() => {
            setModalVisible(false);
        }, 3000);
    }, []);

    const eliminarDelCarrito = useCallback((index) => {
        setCarrito(prevCarrito => {
            const nuevoCarrito = [...prevCarrito];
            nuevoCarrito.splice(index, 1);
            return nuevoCarrito;
        });
    }, []);

    const productosFiltrados = productos.filter(producto => {
        return (producto.nombre.toLowerCase().includes(busquedaProducto.toLowerCase()) &&
            (!filtroCategoria || producto.categoria.toLowerCase() === filtroCategoria.toLowerCase()));
    });

    const productosAMostrar = productosFiltrados.slice(0, productosCargados);

    const cargarMasProductos = useCallback(() => {
        setProductosCargados(prevProductosCargados => prevProductosCargados + 24);
    }, []);

    const handleEnviarProductoNoEncontrado = () => {
        const mensaje = `¡Hola! No encontré el producto que estoy buscando. \n\nNombre del Producto: ${nombreProductoNoEncontrado}\nDescripción: ${descripcionProductoNoEncontrado}`;
        const url = `https://api.whatsapp.com/send?phone=${telefonoWhatsApp}&text=${encodeURIComponent(mensaje)}`;
        window.open(url, '_blank');
    };

    return (
        <div ref={productosListRef} className="container mx-auto p-4">
            <div className='py-8 text-center font-black text-4xl'>Marketplace</div>
            <div className="mb-4 flex flex-col md:flex-row md:justify-between md:items-center bg-gray-100 p-4 rounded-lg">
                <input
                    type="text"
                    placeholder="Buscar producto"
                    value={busquedaProducto}
                    onChange={buscarProducto}
                    className="mb-4 md:mb-0 border border-gray-300 rounded-full py-2 px-4 w-full md:w-1/3"
                />
                <div className="flex flex-wrap items-center gap-2 w-full md:w-2/3 justify-center text-sm">
                    <button
                        onClick={() => handleClickCategoria('')}
                        className={`px-4 py-2 rounded-full ${filtroCategoria === '' ? 'bg-gray-800 text-white' : 'bg-gray-300 text-gray-700'}`}
                    >
                        Todos
                    </button>
                    {[...new Set(productos.map(producto => producto.categoria))].map(categoria => (
                        <button
                            key={categoria}
                            onClick={() => handleClickCategoria(categoria)}
                            className={`px-4 py-2 rounded-full ${filtroCategoria === categoria ? 'bg-gray-800 text-white' : 'bg-gray-300 text-gray-700'}`}
                        >
                            {categoria}
                        </button>
                    ))}
                </div>
            </div>
            {loading && <div className="text-center">Cargando productos...</div>}
            {error && <div className="text-center text-red-500">{error}</div>}
            {productosFiltrados.length === 0 && !loading && (
                <div className="text-center text-gray-700 flex flex-col md:flex-row items-center">
                    <div className='my-8 w-full lg:w-1/3 flex flex-col justify-center items-center space-y-12 border py-12 rounded-lg shadow mr-0 md:mr-4'>
                        <CiFaceSmile size={96} className='text-green-500' />
                        <p>No se encontraron productos para tu búsqueda.</p>
                    </div>
                    <div className="mt-8 pt-4 bg-white shadow-md rounded px-8 border border-green-500 border-t-4 border-b-4 pb-8 mb-4">
                        <h3 className="text-lg md:text-2xl font-extrabold mb-4 rounded-lg">¿No encontraste lo que buscabas?</h3>
                        <p className="text-gray-700 mb-4">
                            Si no encontraste el producto que buscas, por favor llena el siguiente formulario y envíanos la información a través de WhatsApp. Estaremos encantados de ayudarte a encontrar lo que necesitas.
                        </p>
                        <form className="">
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2 border-t pt-4" htmlFor="nombreProductoNoEncontrado">
                                    Nombre del Producto
                                </label>
                                <input
                                    id="nombreProductoNoEncontrado"
                                    type="text"
                                    value={nombreProductoNoEncontrado}
                                    onChange={(e) => setNombreProductoNoEncontrado(e.target.value)}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    placeholder="Ingresa el nombre del producto"
                                />
                            </div>
                            <div className="mb-6">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="descripcionProductoNoEncontrado">
                                    Descripción del Producto
                                </label>
                                <textarea
                                    id="descripcionProductoNoEncontrado"
                                    value={descripcionProductoNoEncontrado}
                                    onChange={(e) => setDescripcionProductoNoEncontrado(e.target.value)}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    placeholder="Ingresa una descripción del producto"
                                ></textarea>
                            </div>
                            <div className="flex items-center justify-between">
                                <button
                                    type="button"
                                    onClick={handleEnviarProductoNoEncontrado}
                                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                >
                                    Enviar a WhatsApp
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-3">
                {productosAMostrar.map((producto, index) => (
                    <div
                        key={producto.uuid_str}
                        className="bg-white rounded-lg shadow-md p-0 relative cursor-pointer hover:shadow-lg transform hover:-translate-y-1 transition-transform duration-200"
                        onClick={() => mostrarDetalleProducto(index)} // Abrir detalle del producto al hacer clic
                    >
                        <img
                            src={`/panel/productos/${producto.new_filename}`}
                            alt={producto.nombre}
                            className="w-full h-40 md:h-64 object-cover mb-4 rounded-t-lg"
                        />
                        <div className='px-4 pb-2 flex flex-col'>
                            <p className="text-gray-500 mt-0 border-b pb-2 mb-2 text-xs lg:text-md text-center">{producto.categoria}</p>
                            <h3 className="text-md font-bold leading-snug text-sm md:text-lg">{producto.nombre}</h3>
                            {descuento > 0 ? (
                                <div className="flex flex-col justify-between items-center mt-2 text-sm border-t border-b py-2">
                                    <div className="flex flex-row items-center justify-between space-x-2 text-sm">
                                        <div className="font-normal text-gray-500 line-through">
                                            $ {calcularPrecioOferta(producto.precio, descuento).toLocaleString()}
                                        </div>
                                        <p className="bg-red-600 text-white px-2 rounded">-{descuento}%</p>
                                    </div>
                                    <p className="text-gray-900 font-bold text-lg">
                                        <span className="pt-0 font-normal text-sm pr-2">{title_sales}</span>
                                        {producto.precio <= 1 ? 'Consultar' : `$${producto.precio.toLocaleString()}`}
                                    </p>
                                </div>
                            ) : (
                                <div className="flex flex-col justify-between items-center mt-2 text-sm border-t border-b py-2">
                                    <p className="text-gray-900 font-bold text-lg">
                                        <span className="pt-0 font-normal text-sm pr-2">{title_sales}</span>
                                        {producto.precio <= 1 ? 'Consultar' : `$${producto.precio.toLocaleString()}`}
                                    </p>
                                </div>
                            )}
                        </div>
                        <button
                            className={`absolute top-2 right-2 p-1 rounded-full shadow bg-white hover:bg-white bg-opacity-60 hover:bg-opacity-90`}
                            onClick={(e) => {
                                e.stopPropagation();
                                agregarAlCarrito(producto);
                            }}
                        >
                            <IoAdd size={22} className='text-gray-950'/>
                        </button>
                    </div>
                ))}
            </div>
            {productosFiltrados.length > productosCargados && (
                <div className="text-center mt-4">
                    <button
                        onClick={cargarMasProductos}
                        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                    >
                        Cargar más
                    </button>
                </div>
            )}
            {selectedIndex !== null && (
                <ModalProducto
                    productos={productosFiltrados}
                    selectedIndex={selectedIndex}
                    cerrarModal={cerrarModal}
                    agregarAlCarrito={agregarAlCarrito}
                    setSelectedIndex={setSelectedIndex}
                />
            )}
            {carritoAbierto && (
                <Carrito
                    carrito={carrito}
                    setCarritoAbierto={setCarritoAbierto}
                    eliminarDelCarrito={eliminarDelCarrito}
                    actualizarCantidad={actualizarCantidad}
                    nombreUsuario={nombreUsuario}
                    setNombreUsuario={setNombreUsuario}
                />
            )}
            <button
                className={`fixed top-20 right-4 font-bold py-2 px-4 rounded-lg border-2 shadow-lg flex items-center transition duration-300 ease-in-out ${themeColors ? themeColors.background : 'bg-gray-700'} ${themeColors ? themeColors.hoverBackground : 'hover:bg-green-600'} ${themeColors ? themeColors.text : 'text-white'}`}
                onClick={() => setCarritoAbierto(true)}
                aria-label={`Abrir carrito de compras con ${carrito.length} artículos`}
            >
                <div className="relative">
                    <FaShoppingCart className="w-6 h-6" aria-hidden="true" />
                    {carrito.length > 0 && (
                        <span className="absolute -top-2 -right-2 bg-red-500 text-white text-xs font-bold rounded-full h-5 w-5 flex items-center justify-center">
                            {carrito.length}
                        </span>
                    )}
                </div>
                <span className="ml-2">Carrito</span>
            </button>
            <ModalNotificacion mensaje={modalMensaje} visible={modalVisible} onClose={() => setModalVisible(false)} />
        </div>
    );
};

export default ProductosList;
