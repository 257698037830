import React from 'react';
import useApiStyles from './useApiStyles';
import { GrSecure } from "react-icons/gr";
import packageJson from '../../package.json';

const Cencomer = () => {
  const currentYear = new Date().getFullYear();
  const themeColors = useApiStyles(); // Usa el hook useApiStyles para obtener los estilos del tema

  return (
    <div className={`${themeColors ? themeColors.background : 'bg-gray-900'} shadow-md`}>
      <div className={`text-start font-light text-xs md:text-sm p-2 flex flex-col md:flex-row justify-start space-x-2 py-4 ${themeColors ? themeColors.text : 'text-gray-300'}`}>
        <a href='/panel' target='_blank' className="flex items-center">
          <GrSecure size={22} />
        </a>
        <p className='ml-4 md:ml-8'>
          {currentYear}
          <span className="px-2 font-semibold">
            <a href='https://cencomer.com' className={`${themeColors ? themeColors.link : 'text-blue-500'} hover:underline`}>
              By Cencomer.com
            </a>
          </span>
          todos los derechos reservados.
        </p>
        <p>Versión: {packageJson.version}</p>
      </div>
    </div>
  );
};

export default Cencomer;
