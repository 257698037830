import React, { useState, useEffect, useCallback } from 'react';
import { IoLogoWhatsapp } from "react-icons/io5";
import useCompanyInfo from './useCompanyInfo';
import { SlLocationPin } from "react-icons/sl";
import { v4 as uuidv4 } from 'uuid';
import TerminosModal from './TerminosModal';
import useApiStyles from './useApiStyles';

const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

const CarritoProducto = ({ producto, index, actualizarCantidad, eliminarDelCarrito, themeColors }) => (
    <div key={index} className="mb-4 border-b pb-4 text-sm">
        <h3 className="font-semibold">{producto.nombre}</h3>
        <div className='flex flex-row space-x-3 pt-3 items-center'>
            <p className="text-gray-600">${producto.precio.toLocaleString()}</p>
            <div className="flex items-center">
                <button
                    onClick={() => actualizarCantidad(index, producto.cantidad - 1)}
                    className={`bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-1 px-2 rounded-l ${themeColors ? themeColors.button : ''}`}
                >
                    -
                </button>
                <p className="px-2">{producto.cantidad}</p>
                <button
                    onClick={() => actualizarCantidad(index, producto.cantidad + 1)}
                    className={`bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-1 px-2 rounded-r ${themeColors ? themeColors.button : ''}`}
                >
                    +
                </button>
            </div>
            <button
                onClick={() => eliminarDelCarrito(index)}
                className={`text-red-600 hover:text-red-800 underline-none ${themeColors ? themeColors.deleteButton : ''}`}
            >
                Eliminar
            </button>
        </div>
    </div>
);

const Carrito = ({ carrito, setCarritoAbierto, eliminarDelCarrito, actualizarCantidad, nombreUsuario, setNombreUsuario }) => {
    const [direccion, setDireccion] = useState('');
    const [telefono, setTelefono] = useState('');
    const [observaciones, setObservaciones] = useState('');
    const [totalArticulos, setTotalArticulos] = useState(0);
    const [totalCosto, setTotalCosto] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [telefonoError, setTelefonoError] = useState('');
    const [ciudad, setCiudad] = useState('');
    const [carritoId] = useState(uuidv4().slice(0, 8)); // Genera un UUID corto para el carrito
    const [isModalOpen, setIsModalOpen] = useState(false); // Estado para controlar el modal de términos
    const [aceptaTerminos, setAceptaTerminos] = useState(true); // Estado para controlar la aceptación de términos

    const { telefonoWhatsApp, title, tipo_carrito } = useCompanyInfo() || {};
    const companyPhone = telefonoWhatsApp || "Teléfono no disponible";
    const carritoTitle = tipo_carrito || "Carrito de Compras";

    const themeColors = useApiStyles(); // Obtenemos los colores del tema

    useEffect(() => {
        const totalArticulos = carrito.reduce((total, producto) => total + producto.cantidad, 0);
        const totalCosto = carrito.reduce((total, producto) => total + producto.precio * producto.cantidad, 0);

        setTotalArticulos(totalArticulos);
        setTotalCosto(totalCosto);
    }, [carrito]);

    useEffect(() => {
        const fetchLocation = async () => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    async (position) => {
                        const { latitude, longitude } = position.coords;
                        try {
                            const response = await fetch(`https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=11d31dc095df4030b7a51273d1f274ed`);
                            const data = await response.json();
                            if (data.results.length > 0) {
                                setCiudad(data.results[0].components.city || 'Ciudad no disponible');
                            } else {
                                setCiudad('Ciudad no disponible');
                            }
                        } catch (error) {
                            console.error('Error al obtener la ciudad:', error);
                            setCiudad('Error al obtener la ciudad');
                        }
                    },
                    (error) => {
                        console.error('Error al obtener la geolocalización:', error);
                        setCiudad('Error al obtener la ciudad');
                    }
                );
            } else {
                setCiudad('Geolocalización no soportada');
            }
        };

        fetchLocation();
    }, []);

    const debouncedSetNombreUsuario = useCallback(debounce(setNombreUsuario, 300), [setNombreUsuario]);
    const debouncedSetDireccion = useCallback(debounce(setDireccion, 300), []);
    const debouncedSetTelefono = useCallback(debounce(setTelefono, 300), []);

    const handleNombreUsuarioChange = (e) => {
        debouncedSetNombreUsuario(e.target.value);
    };

    const handleDireccionChange = (e) => {
        debouncedSetDireccion(e.target.value);
    };

    const handleTelefonoChange = (e) => {
        const value = e.target.value;
        debouncedSetTelefono(value);

        // Validar el formato del número de teléfono
        const telefonoRegex = /^[0-9]{10}$/; // Suponiendo que el número de teléfono debe tener 10 dígitos
        if (!telefonoRegex.test(value)) {
            setTelefonoError('El número de teléfono no es válido. Debe contener 10 dígitos.');
        } else {
            setTelefonoError('');
        }
    };

    const handleObservacionesChange = (e) => {
        setObservaciones(e.target.value);
    };

    const abrirWhatsApp = () => {
        const fechaHora = new Date().toLocaleString(); // Obtener la fecha y hora actual
        let mensaje = `Pedido ID: ${carritoId}\nFecha y Hora: ${fechaHora}\n\n¡Hola! Soy ${nombreUsuario}. \n\nInformación de contacto\n\nDirección: ${direccion} \nTeléfono: ${telefono} \nCiudad: ${ciudad} \n\nMi pedido es el siguiente:\n`;

        carrito.forEach((producto, index) => {
            mensaje += `${index + 1}. ${producto.nombre} (x ${producto.cantidad}) - $${(producto.precio * producto.cantidad).toLocaleString()}\n`;
        });

        mensaje += `\nTotal de artículos: ${totalArticulos}\n`;
        mensaje += `Total del pedido: $${totalCosto.toLocaleString()}\n`;

        if (observaciones) {
            mensaje += `\nObservaciones: ${observaciones}\n`;
        }

        mensaje += `\nYo, ${nombreUsuario} (${telefono}), he leído y acepto los términos y condiciones del sitio ${title}.\n`;

        const telefonoWhatsApp = companyPhone; // Número de teléfono de WhatsApp

        window.open(`https://wa.me/${telefonoWhatsApp}?text=${encodeURIComponent(mensaje)}`, '_blank');
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!nombreUsuario || !direccion || !telefono) {
            setError('Por favor completa todos los campos obligatorios.');
            return;
        }

        if (telefonoError) {
            setError(telefonoError);
            return;
        }

        if (carrito.length === 0) {
            setError('El carrito está vacío. Por favor agrega al menos un artículo.');
            return;
        }

        if (!aceptaTerminos) {
            setError(`Por favor acepta los términos y condiciones de ${title}.`);
            return;
        }

        setError('');
        setLoading(true);
        abrirWhatsApp();
        setLoading(false);
    };

    return (
        <>
            <div className="fixed inset-y-0 right-0 max-w-xs w-full bg-white shadow-lg overflow-y-auto z-50">
                <div className={`flex justify-between items-center mb-2 py-4 border-b-4 border-green-600 px-4 rounded-none ${themeColors ? themeColors.background : 'bg-gray-800'} ${themeColors ? themeColors.border : 'border-b-green-600'} ${themeColors ? themeColors.text : 'text-white'} shadow`}>
                    <h2 className="text-lg font-semibold">{carritoTitle}</h2>
                    <button className={`hover:${themeColors ? themeColors.hoverText : 'text-gray-400'}`} onClick={() => setCarritoAbierto(false)}>
                        <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div className='p-4'>
                    {error && <div className={`mb-4 ${themeColors ? themeColors.errorText : 'text-red-500'}`}>{error}</div>}
                    {carrito.map((producto, index) => (
                        <CarritoProducto
                            key={index}
                            producto={producto}
                            index={index}
                            actualizarCantidad={actualizarCantidad}
                            eliminarDelCarrito={eliminarDelCarrito}
                            themeColors={themeColors}
                        />
                    ))}
                    <div className="border-t pt-4 mb-4">
                        <p className="text-sm font-semibold">Total de artículos: {totalArticulos}</p>
                        <p className="text-sm font-semibold">Total del pedido: ${totalCosto.toLocaleString()}</p>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <input
                            type="text"
                            placeholder="Tu nombre"
                            className={`border px-3 py-2 w-full rounded-md mb-2 text-md ${themeColors ? themeColors.inputBorder : 'border-gray-300'} shadow-sm`}
                            onChange={handleNombreUsuarioChange}
                            required
                        />
                        <input
                            type="text"
                            placeholder="Tu dirección"
                            className={`border px-3 py-2 w-full rounded-md mb-2 text-md ${themeColors ? themeColors.inputBorder : 'border-gray-300'} shadow-sm`}
                            onChange={handleDireccionChange}
                            required
                        />
                        <input
                            type="text"
                            placeholder="Tu teléfono"
                            className={`border px-3 py-2 w-full rounded-md mb-2 text-md ${themeColors ? themeColors.inputBorder : 'border-gray-300'} shadow-sm`}
                            onChange={handleTelefonoChange}
                            required
                        />
                        {telefonoError && <div className={`mb-2 ${themeColors ? themeColors.errorText : 'text-red-500'}`}>{telefonoError}</div>}
                        <textarea
                            placeholder="Observaciones"
                            className={`border px-3 py-2 w-full rounded-md mb-2 text-md ${themeColors ? themeColors.inputBorder : 'border-gray-300'} shadow-sm`}
                            value={observaciones}
                            onChange={handleObservacionesChange}
                        />
                        {ciudad &&
                            <div className='py-2 mb-2 bg-slate-50 flex flex-row items-center justify-center space-x-2 border rounded-lg shadow-sm'>
                                <SlLocationPin size={20} />
                                <p className="text-center text-sm text-gray-500">Ubicación: {ciudad}</p>
                            </div>
                        }
                        <div className="my-4 p-2 border rounded-lg shadow-sm">
                            <label className="flex items-center space-x-2">
                                <input
                                    type="checkbox"
                                    checked={aceptaTerminos}
                                    onChange={() => setAceptaTerminos(!aceptaTerminos)}
                                    className={`form-checkbox h-4 w-4 ${themeColors ? themeColors.checkbox : 'text-blue-600'}`}
                                />
                                <span className='text-sm text-gray-700'>Al enviar el pedido, acepto los términos y condiciones de {title}.</span>
                            </label>
                        </div>
                        <div className='p-2 space-y-4'>
                            <button
                                type="button"
                                className={`border shadow-sm py-2 px-4 rounded flex items-center text-sm w-full ${themeColors ? themeColors.button : 'bg-gray-50 hover:bg-gray-200 text-gray-600 border-gray-500'}`}
                                onClick={() => setIsModalOpen(true)}
                            >
                                Ver Términos y Condiciones
                            </button>
                            <button
                                type="submit"
                                className={`font-bold py-2 px-4 rounded flex items-center w-full mb-2 bg-green-500 hover:bg-green-600 text-white shadow-sm`}
                                disabled={loading}
                            >
                                <IoLogoWhatsapp className="w-6 h-6 mr-2" />
                                {loading ? 'Enviando...' : 'Enviar pedido'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <TerminosModal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
            />
        </>
    );
};

export default Carrito;
