import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';

const Empresa = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const timestamp = new Date().getTime(); // Generar timestamp
                const response = await axios.get(`/panel/empresa/nosotros.json?timestamp=${timestamp}`);
                setData(response.data);
            } catch (error) {
                console.error('Error loading JSON data:', error);
            }
        };

        fetchData();
    }, []);

    if (!data) {
        return <div>Loading...</div>;
    }

    const { heroSection, contentSection, hashtags } = data;

    return (
        <div className="container mx-auto p-4 mt-16 mb-12">
            <Helmet>
                <title>{heroSection.title}</title>
                <meta name="description" content={heroSection.description} />
                <meta property="og:title" content={heroSection.title} />
                <meta property="og:description" content={heroSection.description} />
                <meta property="og:image" content={`/panel/empresa/${heroSection.desktopImage}`} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Jimmy Expression" />
                <meta property="article:author" content="Jimmy Expression" />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:title" content={heroSection.title} />
                <meta property="twitter:description" content={heroSection.description} />
                <meta property="twitter:image" content={`/panel/empresa/${heroSection.desktopImage}`} />
            </Helmet>
            
            {/* Hero Section */}
            <div className="relative bg-white shadow-md rounded-lg overflow-hidden">
                {/* Imagen en computadoras (row) */}
                <div className="hidden md:block">
                    <img src={`/panel/empresa/${heroSection.desktopImage}`} alt="Salón de Belleza" className="w-full h-96 object-cover" />
                    <div className="absolute inset-0 bg-black opacity-60"></div>
                    <div className="absolute inset-0 flex items-center justify-center">
                        <img src={`/panel/empresa/${heroSection.desktopImage}`} alt="Salón de Belleza" className="w-96 h-96 object-cover rounded-lg" />
                        <div className="text-center text-white p-6">
                            <h1 className="text-lg lg:text-4xl font-bold mb-4" dangerouslySetInnerHTML={{ __html: heroSection.title }} />
                            <p className="text-md lg:text-lg mb-6" dangerouslySetInnerHTML={{ __html: heroSection.description }} />
                            <a className="border px-4 py-2 rounded-lg font-semibold" href={heroSection.buttonLink}>{heroSection.buttonText}</a>
                        </div>
                    </div>
                </div>

                {/* Imagen en dispositivos móviles (columna) */}
                <div className="md:hidden">
                    <img src={`/panel/empresa/${heroSection.desktopImage}`} alt="Salón de Belleza" className="w-full h-96 object-cover" />
                    <div className="absolute inset-0 bg-black opacity-60"></div>
                    <div className="absolute inset-0 flex items-center justify-center">
                        <div className="text-center text-white p-6">
                            <h1 className="text-2xl leading-snug md:text-5xl font-bold mb-4" dangerouslySetInnerHTML={{ __html: heroSection.title }} />
                            <p className="text-md leading-snug md:text-xl mb-6" dangerouslySetInnerHTML={{ __html: heroSection.description }} />
                            <a className="border px-4 py-2 rounded-lg shadow-lg font-semibold" href={heroSection.buttonLink}>{heroSection.buttonText}</a>
                        </div>
                    </div>
                </div>
            </div>

            {/* Content Section */}
            <div className="bg-white shadow-md rounded-lg overflow-hidden mt-8 p-6">
                <img src={`/panel/empresa/${heroSection.desktopImage}`} alt="Salón de Belleza" className="w-full h-96 object-cover mb-4 rounded-lg md:hidden" />
                <div dangerouslySetInnerHTML={{ __html: contentSection.text }} />
            </div>

            {/* Hashtags Section */}
            <div className="mt-6 space-y-3">
                {hashtags.split(',').map((hashtag, index) => (
                    <span key={index} className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2">{hashtag}</span>
                ))}
            </div>
        </div>
    );
};

export default Empresa;
