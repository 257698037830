import React from 'react';
import useCompanyInfo from './useCompanyInfo'; // Ajusta la ruta según la ubicación de tu archivo
import UrlValidator from './UrlValidator'; // Ajusta la ruta según la ubicación de tu archivo
import { VscLinkExternal } from "react-icons/vsc";
import { SlArrowRight } from 'react-icons/sl';

const ReservaHomeButton = () => {
  const companyInfoData = useCompanyInfo();

  if (!companyInfoData || !companyInfoData.linkReserva || !companyInfoData.nameReservaInfo) {
    // Si la información de la compañía o la información de pago no están disponibles, retorna null o maneja el caso según tus necesidades
    return null;
  }

  return (
    <div>
      {/* Validar la URL usando UrlValidator */}
      <UrlValidator url={companyInfoData.linkReserva} />

      {/* Reserva Info Button */}
      <div className='text-lg border shadow-md rounded-lg bg-white w-full lg:w-80'>
        <a href={companyInfoData.linkReserva} target="_blank" rel="noopener noreferrer" className="flex justify-between space-x-2 items-center w-full text-center rounded-md px-4 py-2 leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-opacity-90">
          <VscLinkExternal size={28} className="mr-2 text-gray-800" />
          <div className='text-sm text-gray-800 text-start'>
            <span className='font-semibold'>{companyInfoData.nameReservaInfo}</span>
          </div>
          <div className="flex-shrink-0">
            <SlArrowRight />
          </div>
        </a>
        {/* <div className='text-start flex flex-col space-y-2 p-2 md:hidden'>
          <p className='text-sm border-t pt-2'>
            Información de reservas.
          </p>
          <p className='text-xs text-gray-600'>
            Permite a los usuarios realizar reservas directamente desde el sitio web.
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default ReservaHomeButton;
