import React from 'react';
import CompanyInfo from '../components/CompanyInfo';
import ProductosList from '../components/ProductosList';
import FloatingActionButton from '../components/FloatingActionButton';
import Gallery from '../components/Gallery';
import VideoGallery from '../components/VideoGallery';
import GoogleMap from '../components/GoogleMap';

const Home = () => {
  return (
    <>
      <header id='inicio' className="App-header bg-gray-100 p-2 md:p-8 flex flex-col items-center justify-center">
        <div className='w-full mt-0'>
          <CompanyInfo />
        </div>

        <div id='productos' className='w-full bg-white mt-6 rounded-lg'>
          <ProductosList />
        </div>

        <div>
          <FloatingActionButton />
        </div>
      </header>

      <section>
        <div id='galeria'>
          <Gallery />
        </div>

        <div id='videos' className='w-full bg-gray-900'>
          <VideoGallery />
        </div>

        <div id='contacto'>
          <GoogleMap />
        </div>
      </section>
    </>
  );
}

export default Home;
